<template>
  <div>
    <div class="container">
      <div>
        <h2>Manage Category</h2>
      </div>
    </div>
    <div class="px-4">
      <md-table
        v-model="searched"
        md-sort="name"
        md-sort-order="asc"
        md-card
        md-fixed-header
      >
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">Users</h1>
          </div>

          <md-field md-clearable class="md-toolbar-section-end">
            <md-input
              placeholder="Search by name..."
              v-model="search"
              @input="searchOnTable"
            />
          </md-field>
        </md-table-toolbar>

        <md-table-empty-state
          md-label="No users found"
          :md-description="`No user found for this '${search}' query. Try a different search term or create a new user.`"
        >
          <md-button class="md-primary md-raised" @click="newUser"
            >Create New User</md-button
          >
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="#" md-sort-by="id">
            <span>{{ item.Code }}</span></md-table-cell
          >
          <md-table-cell md-label="Title" md-sort-by="name" class="">{{
            item.Name
          }}</md-table-cell>
          <md-table-cell md-label="Category" md-sort-by="email">
            <span>{{ item.Price }}</span></md-table-cell
          >
          <md-table-cell md-label="Actions" md-sort-by="email">
            <span>{{ item.Actions }}</span></md-table-cell
          >
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

export default {
  name: "TableSearch",
  data: () => ({
    search: null,
    searched: [],
    users: [
      {
        Code: 1,
        Name: "Shawna Dubbin",
        Price: "1000",
        Actions: "",
      },
    ],
  }),
  methods: {
    newUser() {
      window.alert("Noop");
    },
    searchOnTable() {
      this.searched = searchByName(this.users, this.search);
    },
  },
  created() {
    this.searched = this.users;
  },
};
</script>

<style lang="scss" scoped>
// .md-field {
//   max-width: 500px;
// }
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}
</style>
